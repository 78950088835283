var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "outer" },
        [
          _c(
            "search-panel",
            {
              attrs: { "show-btn": "", flex: "" },
              on: { getList: _vm.getList },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("专题名称")]),
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { placeholder: "请输入专题名称", size: "mini" },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { slot: "button-right", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.editBase("add")
                    },
                  },
                  slot: "button-right",
                },
                [_vm._v(" 新增专题 ")]
              ),
              _c(
                "div",
                {
                  staticStyle: { display: "inline-block" },
                  attrs: { slot: "button-right" },
                  slot: "button-right",
                },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        slot: "button-right",
                        placement: "top-start",
                        title: "注意",
                        width: "600",
                        trigger: "hover",
                        content:
                          "如果重新编辑商品信息（商品名称，商品价格，商品是否参与活动等），需要复查专题模板中录入的商品。如果，此次修改的商品存在于某个专题模板中，需要重新保存一下这个专题模板，操作为管理后台专题模板--> 点击编辑进入详情-->点击右上角保存即可。",
                      },
                      slot: "button-right",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "20px",
                            display: "flex",
                            "align-items": "center",
                          },
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-warning",
                            staticStyle: { color: "#E6A23C" },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                color: "#0D0202",
                                padding: "0 5px",
                              },
                            },
                            [_vm._v("注意事项")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "outer", staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-row-style": { background: "#d9dde1" },
                "header-cell-style": {
                  background: "#d9dde1",
                  color: "#0D0202",
                  fontSize: "12px",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "专题ID", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "专题名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.input
                          ? _c(
                              "span",
                              {
                                staticClass: "bt-pointer",
                                on: {
                                  click: function ($event) {
                                    scope.row.input = true
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.name))]
                            )
                          : _vm._e(),
                        _c("el-button", {
                          attrs: { type: "text", icon: "el-icon-edit" },
                          on: {
                            click: function ($event) {
                              return _vm.editBase("edit", scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editTemplate(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              color: "#F56C6C",
                              "margin-left": "30px",
                            },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmDel(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.tableData && _vm.tableData.length > 0
            ? _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pagination.currentPage,
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.pagination.nowPageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pagination.count,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.operateType === "add" ? "新增专题" : "编辑专题",
            visible: _vm.addTip,
            size: "tiny",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addTip = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动名称:", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: {
                      placeholder: "请输入活动名称",
                      size: "mini",
                      maxlength: "20",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.addTip = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.addSubject("form")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }