<template>
  <div class="app-container">
    <div class="outer">
      <search-panel
        show-btn
        flex
        @getList="getList"
      >
        <div>
          <p>专题名称</p>
          <el-input
            v-model="name"
            placeholder="请输入专题名称"
            style="width:240px;"
            size="mini"
          />
        </div>
        <el-button
          slot="button-right"
          size="mini"
          @click="editBase('add')"
        >
          新增专题
        </el-button>
        <div
          slot="button-right"
          style="display: inline-block;"
        >
          <el-popover
            slot="button-right"
            placement="top-start"
            title="注意"
            width="600"
            trigger="hover"
            content="如果重新编辑商品信息（商品名称，商品价格，商品是否参与活动等），需要复查专题模板中录入的商品。如果，此次修改的商品存在于某个专题模板中，需要重新保存一下这个专题模板，操作为管理后台专题模板--> 点击编辑进入详情-->点击右上角保存即可。"
          >
            <div
              slot="reference"
              style="margin-left:20px;display:flex;align-items:center;"
            >
              <i
                class="el-icon-warning"
                style="color:#E6A23C;"
              ></i>
              <span style="font-size:12px;color:#0D0202;padding:0 5px;">注意事项</span>
            </div>
          </el-popover>
        </div>
      </search-panel>
    </div>

    <div
      class="outer"
      style="margin-top:20px;"
    >
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%;"
        :header-row-style="{background: '#d9dde1'}"
        :header-cell-style="{background: '#d9dde1', color: '#0D0202', fontSize: '12px'}"
      >
        <el-table-column
          prop="id"
          label="专题ID"
          width="100"
        />
        <el-table-column
          prop="name"
          label="专题名称"
        >
          <template slot-scope="scope">
            <span
              v-if="!scope.row.input"
              class="bt-pointer"
              @click="scope.row.input=true"
            >{{ scope.row.name }}</span>
            <!-- <input
              v-if="scope.row.input"
              v-model="scope.row.name"
              class="bt-input"
              @blur="updateBase(scope.row,'name')"
            > -->
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="editBase('edit',scope.row)"
            />
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="type"
          label="专题类型"
          width="150"
        >
          <template slot-scope="scope">
            {{ scope.row.type === 'index' ? '首页' : scope.row.type === 'site'? '展馆': '活动' }}
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          prop="address"
          label="活动链接"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.type !== 'index'"
              type="text"
              class="btn"
              :data-clipboard-text="`${baseUrl}/subject/actual/${scope.row.id}?projectCode=${projectCode}`"
              @click="copyUri"
            >
              复制前端链接
            </el-button>
            <el-button
              v-if="scope.row.type === 'index'"
              type="text"
              class="btn"
              :data-clipboard-text="`${baseUrl}/subject/actual/index`"
              @click="copyUri"
            >
              复制前端链接
            </el-button>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          prop="address"
          label="小程序链接"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              class="btn"
              :data-clipboard-text="getWxminiUrl(scope.row.id)"
              @click="copyWxminiUri"
            >
              复制小程序链接
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="activityId"
          label="分享文案"
          width="350"
        >
          <template slot-scope="scope">
            <div class="bt-pointer select-tip">
              <span>{{ scope.row.title }}
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  @click="editBase('edit',scope.row)"
                /></span>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          label="操作"
          width="180"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="editTemplate(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              style="color:#F56C6C;margin-left:30px;"
              @click="confirmDel(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData && tableData.length > 0"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.nowPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="operateType === 'add' ? '新增专题' : '编辑专题'"
      :visible.sync="addTip"
      size="tiny"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
      >
        <el-form-item
          label="活动名称:"
          prop="name"
        >
          <el-input
            v-model="form.name"
            placeholder="请输入活动名称"
            style="width:240px;"
            size="mini"
            maxlength="20"
          />
        </el-form-item>
        <!-- <el-form-item
          label="分享文案:"
          prop="activityId"
        >
          <el-select
            v-model="form.activityId"
            filterable
            placeholder="请选择分享文案"
            style="width:240px;"
            size="mini"
          >
            <el-option
              v-for="item in shareList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="专题类型:"
          prop="type"
        >
          <el-select
            v-model="form.type"
            filterable
            placeholder="请选择专题类型"
            style="width:240px;"
            size="mini"
            :disabled="operateType === 'edit'"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            />
          </el-select>
        </el-form-item> -->
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="mini"
          @click="addTip = false"
        >取 消</el-button>
        <el-button
          type="primary"
          size="mini"
          @click="addSubject('form')"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Clipboard from 'clipboard';
import qs from 'qs';
import { projectConfigDetail } from '@/utils/projectConfig';
// import { log } from 'logan-web'

export default {
  name: 'subject',
  data() {
    return {
      name: '', // 搜索 专题名称
      loading: false,
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      }, // 分页数据
      tableData: [], // 列表数据
      // shareList: [], // 分享文案列表 数据
      selectTip: false,
      update_share_id: '',
      addTip: false, // 弹窗是否显示
      operateType: 'add',
      form: {
        name: '', // 新增时的 专题名称
        activityId: '', // 新增时的 文案ID
        type: 'active',
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
        // activityId: [
        //   { required: true, message: '请选择分享文案', trigger: 'change' }
        // ],
        // type: [
        //   { required: true, message: '请选择专题类型', trigger: 'change' }
        // ]

      },

      // typeList: [
      //   { label: '首页', value: 'index', disabled: false },
      //   { label: '专题活动', value: 'active', disabled: false },
      //   { label: '展馆', value: 'site', disabled: false }
      // ],
      projectCode: '',
    };
  },
  mounted() {
    // this.getShareList()
    this.getList();
    this.baseUrl = projectConfigDetail.h5Url;
    this.projectCode = localStorage.getItem('projectCode') || 3300;
  },
  methods: {
    // 获取分享文案列表
    // getShareList() {
    //   this.$axios({
    //     method: 'get',
    //     url: this.$api.tradeActivity.page,
    //     params: {
    //       currentPage: 1,
    //       pageSize: 1000
    //     }
    //   }).then((res) => {
    //     if (res.code === 0) {
    //       this.shareList = res.data.records
    //     } else {
    //       this.$message(res.error_msg)
    //     }
    //   })
    // },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val;
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios({
        method: 'post',
        url: this.$api.tradePluginTemplate.page,
        data: {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          name: this.name,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.records;
          this.pagination.count = Number(res.data.total);
        } else {
          this.$message(res.error_msg);
        }
        this.loading = false;
      });
    },
    // 编辑专题名称
    editBase(type, row) {
      this.operateType = type;
      this.addTip = true;
      if (type === 'add') {
        this.form = {
          name: '',
          activityId: '',
          type: 'active',
        };
      } else {
        this.form = {
          // name: row.name,
          // activityId: row.activityId,
          // type: row.type
          ...row,
        };
      }
    },
    // updateBase(row, type) {
    //   this.$axios.post(this.$api.tradePluginTemplate.edit_info, {
    //     id: row.id,
    //     activityId: row.activityId
    //   }).then((res) => {
    //     let r = res
    //     if (r.code === 0) {
    //       this.$set(row, 'select', false)
    //       this.getList(this.pagination.page)
    //       this.$message('操作成功！')
    //     }
    //   })
    // },
    // 编辑状态
    editTemplate(row) {
      let link;
      if (row.type === 'index') {
        link = `${this.baseUrl}/subject/actual/index`;
      } else {
        link = `${this.baseUrl}/subject/actual/${row.id}?projectCode=${this.projectCode}`;
      }
      this.$router.push({ path: '/operation/subject/detail', query: { id: row.id, type: row.type, currentlink: link } });
    },
    // 新增专题模版
    addSubject(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = this.operateType === 'add' ? this.$api.tradePluginTemplate.add : this.$api.tradePluginTemplate.edit_info;
          // let data = this.operateType === 'add' ? {
          //   ...this.form
          // } : {
          //   ...this.form,

          // }
          this.$confirm('是否确认保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.$axios({
              method: 'post',
              url,
              data: this.form,
            }).then((res) => {
              let r = res;
              if (r.code === 0) {
                this.getList();
                this.$refs[formName].resetFields();
                this.$message.success('操作成功');
              }
              this.addTip = false;
            });
          }).catch(() => {
          });
        } else {
          return false;
        }
      });
    },
    // 复制功能
    copyUri() {
      let clipboard = new Clipboard('.btn');
      clipboard.on('success', (e) => {
        this.$message.success('复制成功');
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message.error('该浏览器不支持自动复制');
        // 释放内存
        clipboard.destroy();
      });
    },
    // 复制小程序链接功能
    copyWxminiUri() {
      let clipboard = new Clipboard('.btn');
      clipboard.on('success', (e) => {
        this.$message.success('复制成功');
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message.error('该浏览器不支持自动复制');
        // 释放内存
        clipboard.destroy();
      });
    },
    // 删除二次确认
    confirmDel(row) {
      this.$confirm('确认要删除此专题模版吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios.post(
          this.$api.tradePluginTemplate.remove,
          qs.stringify(
            {
              id: row.id,
            },
          ),
        ).then((res) => {
          let r = res.data;
          console.log(res, '===');
          if (res.code === 0) {
            // if (row.type === 'index') {
            //   this.typeList[0].disabled = false
            // }
            this.getList();
          }
        });
      }).catch(() => {});
    },
    getWxminiUrl(id) {
      return `pages/webview/index?url=${encodeURIComponent(`${this.baseUrl}/subject/actual/${id}?projectCode=${this.projectCode}`)}`;
    },
  },
};
</script>

<style scoped lang='scss'>
  .select-tip{
    line-height:40px;
    height:40px;
    position: relative;
  }
  .notice {
    line-height: 30px;
    background: #FFFAD6;
    color: #A05600;
    padding: 10px;
    margin-bottom: 10px;
  }
</style>
